import { NumberFormatValues } from 'react-number-format';

type HookType = ({ handleChangeDisplacement }) => {
  handleChange: (value: number) => void;
  isAllowed: ({ floatValue }: NumberFormatValues) => boolean;
};

const useDateDisplacement: HookType = ({ handleChangeDisplacement }) => {
  const handleChange = (value: number) => {
    if (value || value === 0) {
      handleChangeDisplacement(value);
    }
  };

  const isAllowed = ({ floatValue }: NumberFormatValues) => {
    if (Number(floatValue) >= 0) return true;
    if (typeof floatValue === 'undefined') return true;
    return false;
  };

  return {
    handleChange,
    isAllowed,
  };
};

export default useDateDisplacement;
