import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { QueryClient, useQuery, useQueryClient } from 'react-query';
import moment from 'moment';

import Services from '../../../../services/schedule/ScheduleServices';

import { ContractPaymentSchedule, Event, ScheduleDate } from '../../../../models/ContractPayments';
import { ForeignData } from '../../../../models/Contracts';
import { IPaymentDate } from '../interfaces/IPaymentDate';

import useContractDetail from '../../../ContractDetail/Core/customHooks/useContractDetail';
import { useSnackbar } from 'notistack';

const services = new Services();

const useTowerScheduleData = (towerId: string) => {
  return useQuery<ScheduleDate[]>(
    'schedule-by-tower',
    async () => {
      const { data: scheduleDates } = await services.getLabeledDates(towerId);
      return scheduleDates;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 300000,
    },
  );
};

const addDisplacement = (date: number, displacement: number) => {
  return moment(Number(date)).add(displacement, 'months').valueOf();
};

const subtractDisplacement = (date: number, displacement: number) => {
  return moment(Number(date)).subtract(displacement, 'months').valueOf();
};

const calculateDisplacement = (date: number, oldDisplacement: number, displacement: number) => {
  if (displacement >= 0) {
    const customDate = subtractDisplacement(date || 0, oldDisplacement || 0);
    return addDisplacement(customDate, displacement);
  } else {
    const customDate = addDisplacement(date || 0, oldDisplacement * -1 || 0);
    return subtractDisplacement(customDate, displacement * -1);
  }
};

interface IPaymentDateTypeOption {
  label: string;
  value: string;
  default: boolean;
}

const usePaymentDateType = ({ currentContractPaymentSchedule, data, handleChangeData, currentContractId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { towerId }: { towerId: string } = useParams();
  const queryClient: QueryClient = useQueryClient();

  const paymentDateTypesOptions: IPaymentDateTypeOption[] = [
    {
      value: 'MANUAL',
      label: 'Manual',
      default: currentContractPaymentSchedule?.paymentDateType === 'MANUAL',
    },
    {
      value: 'EVENT',
      label: 'Hito',
      default: currentContractPaymentSchedule?.paymentDateType === 'EVENT',
    },
    {
      value: 'SCHEDULE',
      label: 'Evento de torre',
      default: currentContractPaymentSchedule?.paymentDateType === 'SCHEDULE',
    },
  ];

  const { data: scheduleDates } = useTowerScheduleData(towerId);

  const [eventList, setEvents] = useState<Event[]>();
  const [autocompleteEventValue, setAutocompleteEventValue] = useState<Event | null>(null);
  const [inputEventValue, setInputEventValue] = useState<string>('');

  const [scheduleList, setSchedules] = useState<ScheduleDate[]>();
  const [autocompleteScheduleValue, setAutocompleteScheduleValue] = useState<ScheduleDate | null>(null);
  const [inputScheduleValue, setInputScheduleValue] = useState<string>('');
  const [helpDateValue, setHelpDateValue] = useState<number | null>(null);

  const [minValidDate, setMinValidDate] = useState<number>(0);
  const [maxValidDate, setMaxValidDate] = useState<number>(0);

  const foreignData = queryClient.getQueryData<ForeignData>('contracts-foreign-list');

  const { currentContract } = useContractDetail(currentContractId);

  const generatePaymentDates = (paymentDate, installments, paymentCycle) => {
    const increment = (date, cycle, i) => {
      switch (cycle) {
        case 'MONTHLY':
          return date.clone().add(i, 'months');
        case 'QUARTERLY':
          return date.clone().add(i * 3, 'months');
        case 'SEMIANNUALLY':
          return date.clone().add(i * 6, 'months');
        case 'YEARLY':
          return date.clone().add(i, 'years');
        default:
          throw new Error('Invalid payment cycle');
      }
    };

    return Array.from({ length: installments }).map((_, i) =>
      increment(moment(paymentDate), paymentCycle, i).format('YYYY-MM-DD'),
    );
  };

  const dateValidation = (paymentDate) => {
    const finalDateOfContractPeriod = moment(Number(currentContract?.toDate)).startOf('month');
    const initialDateOfContractPeriod = moment(Number(currentContract?.fromDate)).startOf('month');
    const { installments, paymentCycle } = data;

    const paymentDates = generatePaymentDates(paymentDate, installments, paymentCycle);
    const lastPaymentDate = paymentDates[paymentDates.length - 1] || paymentDate;

    const paymentDateMoment = moment(paymentDate).startOf('month');
    const lastPaymentDateMoment = moment(lastPaymentDate).startOf('month');

    const isValidPeriod =
      lastPaymentDateMoment.isSameOrBefore(finalDateOfContractPeriod) &&
      paymentDateMoment.isSameOrAfter(initialDateOfContractPeriod);

    return isValidPeriod;
  };

  useEffect(() => {
    queryClient.invalidateQueries(['contract-events', towerId]);
    const eventListData = queryClient.getQueryData<Event[]>(['contract-events', towerId]);

    if (!!eventListData) {
      setEvents(eventListData);
    }
    if (!!scheduleDates) {
      setSchedules(scheduleDates);
    }
    if (!!foreignData) {
      const { statistics } = foreignData as ForeignData;

      setMinValidDate(statistics.startStageDate);
      setMaxValidDate(statistics.endStageDate);
    }
  }, [scheduleDates, towerId, foreignData, currentContractId]);

  useEffect(() => {
    if (currentContractPaymentSchedule?.paymentDateType === 'EVENT') {
      setAutocompleteEventValue(currentContractPaymentSchedule?.event);
    }

    if (currentContractPaymentSchedule?.paymentDateType === 'SCHEDULE') {
      const schedule: ScheduleDate = {
        customDate: currentContractPaymentSchedule?.finalDate,
        id: currentContractPaymentSchedule?.scheduleLabel,
        scheduleId: currentContractPaymentSchedule?.scheduleId,
        name: currentContractPaymentSchedule?.scheduleLabelDescription,
      };
      setAutocompleteScheduleValue(schedule);
    }
  }, [currentContractPaymentSchedule]);

  const handleChangeDisplacement = (displacement: number) => {
    const finalDate = calculateDisplacement(data?.finalDate || 0, data?.displacement || 0, displacement);

    handleChangeData({
      finalDate,
      displacement,
    });
  };

  const handleChangeDateValue = (key: keyof ContractPaymentSchedule | null, value: IPaymentDate) => {
    const finalDate = addDisplacement(value?.customDate || 0, data.displacement || 0);

    const isValidPeriod = dateValidation(value.customDate);

    if (!isValidPeriod) {
      enqueueSnackbar('Algunas fechas están fuera del período del contrato.', {
        variant: 'error',
      });
    } else {
      switch (key) {
        case 'paymentDate':
          handleChangeData({
            finalDate,
            paymentDate: value?.customDate || undefined,
            scheduleId: undefined,
            scheduleLabel: undefined,
            scheduleLabelDescription: undefined,
            eventId: undefined,
            event: undefined,
            displacement: 0,
          });
          break;
        case 'scheduleId':
          setHelpDateValue(value?.customDate);
          handleChangeData({
            finalDate,
            paymentDate: undefined,
            scheduleId: value?.id,
            scheduleLabel: value?.label,
            scheduleLabelDescription: value?.description,
            eventId: undefined,
            event: undefined,
          });
          break;
        case 'eventId':
          handleChangeData({
            finalDate,
            paymentDate: undefined,
            scheduleId: undefined,
            scheduleLabel: undefined,
            scheduleLabelDescription: undefined,
            eventId: value?.id,
            event: {
              customDate: value.customDate || 0,
              description: value.description || '',
              id: value?.id || 0,
              displacement: 0,
            },
          });
          setHelpDateValue(value?.customDate);
          break;
        case 'paymentDateType':
          handleChangeData({
            finalDate: undefined,
            paymentDateType: value.type,
            paymentDate: undefined,
            scheduleId: undefined,
            scheduleLabel: undefined,
            scheduleLabelDescription: undefined,
            eventId: undefined,
            event: undefined,
            displacement: 0,
          });
          setHelpDateValue(null);
          break;
      }
    }
  };

  return {
    eventList,
    autocompleteEventValue,
    setAutocompleteEventValue,
    inputEventValue,
    setInputEventValue,
    scheduleList,
    autocompleteScheduleValue,
    setAutocompleteScheduleValue,
    inputScheduleValue,
    setInputScheduleValue,
    paymentDateTypesOptions,
    handleChangeDateValue,
    handleChangeDisplacement,
    helpDateValue,
    minValidDate,
    maxValidDate,
  };
};

export default usePaymentDateType;
