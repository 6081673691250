import { useSelector, useDispatch } from 'react-redux';
import { PreviousControlModel } from '../models/PreviousControlModel';
import { updateEditableRows } from '../../../TotalCashFlow/Core/actions';
import useCalculatePreviousControlRows from './useCalculatePreviousControlRows'
import Numbers from '../../../../helpers/numbers';

function usePreviousControl() {
  const dispatch = useDispatch();
  const { editableBudgetRows, budgetRows, contractRowsForBudgetContol } = useSelector(
    (state) => state.cashFlow.root || {},
  );

  const findBudget = (array, budgetId, budgetIndex = null) => {
    if (budgetIndex) {
      const budget = array.find((budget) => budget.budgetId === budgetId && budget.budgetIndex === budgetIndex);

      return budget;
    }
    const row = array.find((row) => row.id === budgetId);
    return row;
  };

  const duplicateValidation = (budgetsArray, idKey) => {
    return budgetsArray.reduce((budgetsWithoutDuplicate, budget) => {
      const id = budget[idKey];
      const duplicate = budgetsWithoutDuplicate.find((item) => item[idKey] === id);
      if (!duplicate) {
        budgetsWithoutDuplicate.push(budget);
      }
      return budgetsWithoutDuplicate;
    }, []);
  };

  const filterBudgets = (idsToFind, newRowState, idToDiscard) => {
    const budgetsFiltered = budgetRows.filter(
      (budget) =>
        idsToFind.includes(budget.budgetId) &&
        budget?.budgetIndex === newRowState?.budgetIndex &&
        budget.budgetId !== idToDiscard,
    );

    const butgetsWithoutDuplicates = duplicateValidation(budgetsFiltered, 'budgetId');

    return butgetsWithoutDuplicates;
  };

  const setBudgetArray = (newValuesArray) => {
    const rowsFiltered = editableBudgetRows.filter((budgetState) => {
      const newValue = newValuesArray.find((newbudget) => newbudget.budgetId === budgetState.budgetId);

      return !newValue || newValue.budgetId !== budgetState.budgetId;
    });

    if (rowsFiltered.length > 0) {
      return [...rowsFiltered, ...newValuesArray];
    }
    return [...newValuesArray];
  };

  const { calculatePercentage, recalculatedRows } = useCalculatePreviousControlRows({
    filterBudgets,
    contractRowsForBudgetContol,
    findBudget,
    budgetRows,
  })

  const handleChange = (e, row) => {
    const convertStringToNumber = Numbers.parseLocaleNumber(e.target.value);

    const TotalBudgetRow = findBudget(budgetRows, 'TOTAL_SALES', row.budgetIndex || row.selectedBudget);

    const totalForPercentage = TotalBudgetRow?.previousBudget || {};

    const newPercentageForEventBudget = calculatePercentage(convertStringToNumber, totalForPercentage);

    const newEventValue = {
      budgetId: row.budgetId || row.id,
      budgetName: row.budgetName || row.name,
      budgetIndex: row.budgetIndex || row.selectedBudget,
      previousBudget: convertStringToNumber,
      budgetPercentage: newPercentageForEventBudget,
      group: row.group,
      item: row.item,
      parentId: row.parentId,
    };

    const newBudgetValues = recalculatedRows(newEventValue, totalForPercentage);

    const newValuesArray = newBudgetValues.concat(newEventValue);

    const newRows = setBudgetArray(newValuesArray);

    dispatch(updateEditableRows(newRows));
  };

  return {
    handleChange,
  };
}

export default usePreviousControl;
