import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateBudgetRows } from '../../../TotalCashFlow/Core/actions';
import { useParams } from 'react-router-dom';
import ContractFlowService from '../../../../services/contractFlow/contractFlowService';
import ResultsStateServices from '../../Services/ResultsStateServices';

function useResultsStateToFetchRows(
  setGroups,
  setSalesCashFlow,
  updateTotalReport,
) {
  const contractServices = new ContractFlowService();
  const salesCashflow = new ResultsStateServices();

  const { towerId } = useParams();
  const [contractData, setContracts] = useState(null);
  const [salesFlow, setSalesFlow] = useState(null);
  const [budgetControl, setBudgetControl] = useState(null);
  const [isLoading, setLoad] = useState(false);
  const [towerWithoutQuotation, setTowerWithoutQuotation] = useState(false);
  const [speedSalesIsMissing, setSpeedSalesIsMissing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const getContracts = async () => {
    try {
      const contractInfo = await contractServices.getContractsInformation(
        towerId,
      );
      const { allInfo, ClientWithoutQuotation, totals } = contractInfo.data;

      setContracts(allInfo);
      setGroups(allInfo);
      updateTotalReport(totals);
      setTowerWithoutQuotation(ClientWithoutQuotation);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const getSalesCashflow = async () => {
    try {
      const salesCashFlow = await salesCashflow.getTotalCashFlow(towerId);

      setBudgetControl(salesCashFlow.data.budgetControl);
      setSalesFlow(salesCashFlow.data);
      setSalesCashFlow(salesCashFlow.data);
      setSpeedSalesIsMissing(salesCashFlow.data.speedSalesIsMissing);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    if (budgetControl) {
      dispatch(updateBudgetRows(budgetControl));
    }
  }, [budgetControl]);

  const forceUpdate = () => {
    if (!isLoading) {
      getContracts();
      getSalesCashflow();
      setLoad(true);
    }
  };

  useEffect(() => {
    if ((!contractData || !salesFlow) && !isLoading) {
      if (!contractData) getContracts();
      if (!salesFlow) getSalesCashflow();
      setLoad(true);
    }
  }, []);

  useEffect(() => {
    if (contractData && salesFlow) {
      setLoad(false);
    }
  }, [contractData, salesFlow]);

  return {
    isLoading,
    towerWithoutQuotation,
    speedSalesIsMissing,
    forceUpdate,
  };
}

export default useResultsStateToFetchRows;
