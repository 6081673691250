import React, { useEffect } from 'react';
import useRowsForResultsState from './useRowsForResultsState';
import { useDispatch } from 'react-redux';
import { updateBudgetRows, updateCutsRows, updateRowsForBudgetContol } from '../../../TotalCashFlow/Core/actions';

function useControlsForResultsState({
  contracts,
  salesRows,
  contractRows,
  financialPerformance,
  financing,
  selectedTab,
  cutsControl,
  budgetControl,
  budgetRows,
  cutsRows,
  selectedBudget,
  totalsReport,
}) {
  const { rows, selection } = useRowsForResultsState({
    contracts,
    salesRows,
    contractRows,
    financialPerformance,
    financing,
    selectedTab,
    totalsReport,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (budgetControl !== budgetRows && !budgetRows) {
      dispatch(updateBudgetRows(budgetControl));
    }

    if (cutsControl !== cutsRows && !cutsRows) {
      dispatch(updateCutsRows(cutsControl));
    }

    if (contractRows) {
      dispatch(updateRowsForBudgetContol(contractRows))
    }
  }, [budgetControl, cutsControl, contractRows]);

  const rowsWithControls = rows.map((row) => {
    const { id } = row;

    const findCutControl = cutsControl && cutsControl.find((cutControl) => cutControl.controlId === id);

    const budgetRowsValidation = budgetRows && budgetRows.length > 0 && budgetRows;

    const findBudget =
      !budgetRowsValidation &&
      budgetControl &&
      budgetControl.find(
        (budgetControl) => budgetControl.budgetId === id && budgetControl.budgetIndex === selectedBudget,
      );

    const findBudgetState =
      budgetRows &&
      budgetRows.find((budgetControl) => budgetControl.budgetId === id && budgetControl.budgetIndex === selectedBudget);

    const findCutState = cutsRows && cutsRows.find((cutRows) => cutRows.controlId === id);

    let previousControl = '-';
    let controlPercentage = '-';
    let createdAt = null;
    let variation = 0;
    let budgetName = '';
    let budgetId = '';
    let budgetIndex;

    if (findCutState && selectedTab.id === 'CUT') {
      previousControl = findCutState.previousCut;
      controlPercentage = findCutState.cutPercentage;
      createdAt = findCutState.createdAt;
      variation = row.total - findCutState.previousCut;
    }

    if (findCutControl && selectedTab.id === 'CUT') {
      previousControl = findCutControl.previousCut;
      controlPercentage = findCutControl.cutPercentage;
      createdAt = findCutControl.createdAt;
      variation = row.total - findCutControl.previousCut;
    }

    if (findBudget && selectedTab.id === 'BUDGET') {
      previousControl = findBudget.previousBudget;
      controlPercentage = findBudget.budgetPercentage;
      variation = row.total - findBudget.previousBudget;
      budgetName = findBudget.budgetName;
      budgetId = findBudget.budgetId;
      budgetIndex = findBudget.budgetIndex;
    }

    if (findBudgetState && selectedTab.id === 'BUDGET') {
      previousControl = findBudgetState.previousBudget;
      controlPercentage = findBudgetState.budgetPercentage;
      variation = row.total - findBudgetState.previousBudget;
      budgetName = findBudgetState.budgetName;
      budgetId = findBudgetState.budgetId;
      budgetIndex = findBudgetState.budgetIndex;
    }

    return {
      ...row,
      previousControl,
      controlPercentage,
      budgetName,
      budgetId,
      budgetIndex,
      cutDate: createdAt,
      variation,
      selectedBudget
    };
  });

  return { rowsWithControls, selection };
}

export default useControlsForResultsState;
